import React, { useEffect } from "react";

const App = () => {
  const appId = "981071426908731"; // Your App ID
  const configId = "1564406280945997"; // Your configuration ID

  useEffect(() => {
    // Load the Facebook SDK script dynamically
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    // Initialize Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: "v21.0",
      });
    };

    // Add message event listener for Embedded Signup
    window.addEventListener("message", (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            console.log("Phone number ID:", phone_number_id);
            console.log("WhatsApp Business Account ID:", waba_id);
          } else if (data.event === "CANCEL") {
            const { current_step } = data.data;
            console.warn("Canceled at step:", current_step);
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            console.error("Error:", error_message);
          }
        }
        document.getElementById("session-info-response").textContent =
          JSON.stringify(data, null, 2);
      } catch {
        console.log("Non-JSON Responses:", event.data);
      }
    });
  }, [appId]);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log("Authorization Code:", code);
      // Transmit the code to your backend for token exchange
    }
    document.getElementById("sdk-response").textContent = JSON.stringify(
      response,
      null,
      2
    );
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: configId,
      response_type: "code",
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "3",
      },
    });
  };

  return (
    <div>
      <h1>Embedded Signup</h1>
      <button
        onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: "#1877f2",
          border: "0",
          borderRadius: "4px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontWeight: "bold",
          height: "40px",
          padding: "0 24px",
        }}
      >
        Login with Facebook
      </button>
      <p>Session info response:</p>
      <pre id="session-info-response"></pre>
      <br />
      <p>SDK response:</p>
      <pre id="sdk-response"></pre>
    </div>
  );
};

export default App;
